p {
  margin-bottom: 0;
}

.ContactCard {
  padding: 10px;
  border-radius: 15px;
  background-color: #fff;
  text-decoration: none;
}
.ContactCard .ContactCard--Content {
  display: flex;
  gap: 10px;
}
.ContactCard .card-title {
  color: #000 !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ContactCard .card-img-top {
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  border-radius: 10px !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.ContactCard .card-body {
  padding: 0 0 0 10px;
}
.ContactCard .card-body .ContactCard--desc {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ContactCard .card-body .ContactCard--desc > div {
  display: flex;
  align-items: flex-start;
  gap: 3px;
  color: #6a6c6a;
  fill: #6a6c6a;
}
.ContactCard .card-body .ContactCard--desc > div p {
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 0 !important;
}

.bookExpert--Btn {
  text-align: center;
  margin-top: 10px;
  padding: 8px;
  background: var(--primary-color);
  border-radius: 8px;
  justify-content: center;
  color: #fff !important;
  font-size: 0.95rem;
}/*# sourceMappingURL=ContactCardResponse.css.map */