.appointment--Confiermed-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .appointment--Confiermed-container .appointment--Confiermed {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    box-shadow: none !important;
    border: none !important;
  }
}
.appointment--Confiermed-container .appointment--Confiermed {
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: #fff;
  /* Shadows/shadow-2xl */
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  width: 100%;
  max-width: 520px;
}
.appointment--Confiermed-container .appointment--Confiermed h3 {
  color: var(--Colors-Text-text-primary, #101828);
  text-align: center;
  /* Display sm/Medium */
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
}
.appointment--Confiermed-container .appointment--Confiermed p {
  color: var(--Colors-Text-text-secondary, #344054);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.appointment--Confiermed-container .appointment--Confiermed .screener--div {
  border-radius: var(--radius-xl, 12px);
  background: rgba(208, 213, 221, 0.26);
  padding: 14px;
}
.appointment--Confiermed-container .appointment--Confiermed .screener--div p {
  color: var(--Text-text-secondary, #252422);
  text-align: center;
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.appointment--Confiermed-container .appointment--Confiermed .button--group .btn-1 {
  border-radius: var(--radius-md, 8px);
  background: var(--primary-color);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 18px;
  height: 45px;
  text-transform: capitalize;
}
.appointment--Confiermed-container .appointment--Confiermed .button--group .btn-2 {
  border-radius: var(--radius-md, 8px);
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 18px;
  height: 45px;
  text-transform: capitalize;
}
.appointment--Confiermed-container .appointment-details {
  border-radius: var(--radius-md, 8px);
  border: 1px solid #e3e3e3;
}
.appointment--Confiermed-container .appointment-details .detail-header p {
  color: var(--Text-text-placeholder, #807c75);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 143.75% */
}
.appointment--Confiermed-container .appointment-details p {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  margin: 12px 0;
}
.appointment--Confiermed-container .appointment-details p span {
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.appointment--Confiermed-container .appointment-details .expert--detail h5 {
  color: #000;
  font-family: Helvetica;
  font-size: 14.973px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.46px; /* 150% */
  letter-spacing: 0.468px;
  margin-bottom: 0;
}
.appointment--Confiermed-container .appointment-details .expert--detail p {
  color: #757575;
  font-family: Helvetica;
  font-size: 13.684px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.46px; /* 164.127% */
  letter-spacing: 0.468px;
}
.appointment--Confiermed-container .appointment-details .expert--detail div {
  border-radius: var(--radius-sm, 6px);
  background: rgba(115, 88, 155, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  color: #73589b;
  text-align: center;
  font-family: Helvetica;
  font-size: 13.684px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.46px; /* 164.127% */
  letter-spacing: 0.468px;
}/*# sourceMappingURL=Success.css.map */