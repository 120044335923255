.UpcomingAppointmentCard {
  width: 100%;
  justify-content: space-between;
  .name {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-top: 8px;
    margin-bottom: 5px;
    margin-left: -10px;
    margin-right: -10px;
  }
  & > div {
    width: 100%;
    flex: 1;
  }
  & > p {
    font-size: 14px !important;
  }
  .AppointmentType {
    font-size: 13px !important;
    color: #6a6c6a;
    line-height: 130%;
  }
}

.upcomingAppointment,
.default {
  & > div {
    border: 1px solid;
  }
}

.upcomingAppointment {
  & > div {
    border-color: #f3f3f3;
  }
  & > p {
    color: #6a6c6a;
  }
  &:hover {
    & > div {
      border-color: var(--primary-base-color);
      outline-style: solid !important;
      outline-width: 5px !important;
      outline-color: var(--primary-base-opacity30) !important;
      transition: all ease-in-out 0.2s;
    }
    & > p {
      color: var(--primary-base-color);
      transition: all ease-in-out 0.2s;
    }
  }
}
