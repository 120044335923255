.nav-link.active {
    border-radius: 8px;
    background: #dcd0eb;
    font-weight: 600;
    border: none !important;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    flex: inherit !important;
}

.professional-otp-popUp>.modal-dialog {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    position: relative !important;
    margin: 5% auto !important;
    border-radius: 24px !important;
    /* overflow: hidden !important; */
    min-height: 70vh !important;
}

.professional-otp-popUp>.modal-dialog>.modal-content {
    border-radius: 24px !important;
    background: #fff !important;
}

.disclaimer-popUp>.modal-dialog {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    margin: auto !important;
    position: relative !important;
}

.payeeDetail>li>button:hover {
    border: none !important;
}

.payeeDetail>li>button:focus {
    border: none !important;
}

.form-outline .form-control.active~.form-label,
.form-outline .form-control:focus~.form-label {
    transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !important;
}

.form-floating>label {
    position: absolute;
    top: 0;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 0.75rem;
    padding-top: 0.37rem;
    pointer-events: none;
    transform-origin: 0 0;
    transition: all 0.2s ease-out;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
}

.form-control {
    position: relative;
}


.form-check-input:checked {
    background-color: #73589b;
    border-color: #73589b;
}

.file-upload {
    border-radius: 10px;
    border: 1px solid var(--Light-Grey-2, rgba(28, 27, 31, 0.12));
    background: var(--Light-Base-Color-Background, #FFF);
    height: 56px;
    display: flex;
}

.file-upload>.upload-icon {
    border-radius: 10px 0px 0px 10px;
    background: var(--Light-Grey-2, rgba(28, 27, 31, 0.12));
    display: flex;
    align-items: center;
    justify-content: center;
}