.ExpertNotificationsDashboard {
  .ExpertNotificationsDashboard--Header {
    h2 {
      color: #000;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    .Header--Options {
      margin-top: 20px;
      .Option--Item {
        cursor: pointer;
        color: #6a6c6a;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .SelectedItem {
        background: #ffffff;
        color: #212529;
      }
    }
  }
}
.ExpertNotificationsDashboard--Content {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  h6 {
    padding: 20px;
  }
  .Content--Item {
    & > div {
      & > * + * {
        border-top-width: 1px !important;
        border-bottom-width: 0px;
        border-color: #d9d9d9;
      }
    }

    .NotificationRowCard {
      border-top: 1px solid #d9d9d9 !important;
    }
  }
}
