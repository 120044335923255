.UserDashboard--Chat {
  .sentChat {
    .messagesArea {
        display: flex;
        flex-direction: column;
        gap: 10px;
      & > div {
        background: rgba(115, 88, 155, 0.09);
        border-radius: 10px;
        border-bottom-right-radius: 0;
        color: #000;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.21px;
        line-height: 145.023%;
        margin-left: auto;
        padding: 10px;
      }
    }
  }
  .receivedChat{
    .messagesArea{
        &>div{
            background: #f5f5f5;
            border-radius: 10px;
            border-bottom-left-radius: 0;
            color: #000;
            font-family: Roboto,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: .21px;
            line-height: 145.023%;
            margin-right: auto;
            padding: 10px;
        }
    }
  }
  .SendText--Container{
    background-color: rgba(115,88,155,.09);
    // border-top: 1px solid rgba(115,88,155,.141);
    // bottom: 0;
    // left: 0;
    // position: absolute;
    // width: 100%;
    // z-index: 10;
    border-bottom-left-radius: var(--bs-border-radius-lg);
    border-bottom-right-radius: var(--bs-border-radius-lg);

  }
}

.noAppointmentContainer{
  height: 40vh;
}

