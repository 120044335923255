.noResultOnSearch {
  height: 400px;
}

.Search--header-container {
  background: var(--primary-color);
  padding: 90px 0;
}
@media screen and (max-width: 500px) {
  .Search--header-container .header--div {
    width: 90% !important;
  }
}
.Search--header-container .header--div h3 {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 39px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 153.846% */
}
.Search--header-container .header--div p {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}
.Search--header-container .header--div .search--inputDiv {
  padding: 5px;
  max-width: 900px;
}
.Search--header-container .header--div .search--inputDiv input::-moz-placeholder {
  padding-left: 10px;
}
.Search--header-container .header--div .search--inputDiv input::placeholder {
  padding-left: 10px;
}
.Search--header-container .header--div .search--inputDiv .categorySelect {
  height: 56px !important;
  border: 1px solid #eee !important;
  background: #fff;
  border-radius: 0;
  position: relative;
  width: 25% !important;
}
.Search--header-container .header--div .search--inputDiv .search--IconDiv {
  border-radius: 50%;
  background: #000;
  padding: 16px 16px 22px;
  color: #fff;
  height: 56px;
  cursor: pointer;
}
.Search--header-container .header--div .scrollable-options {
  display: flex;
  overflow-x: auto;
}
.Search--header-container .header--div .scrollable-options .options--pill {
  background-color: rgb(218, 200, 243);
  border-radius: 50px !important;
  border: none;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #6c46a0 !important;
  margin: 10px 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Search--header-container .header--div .scrollable-options .options--pill.selected-option {
  background-color: white;
  border: none;
}
.Search--header-container .header--div .popular--searchContainer .popularDiv {
  background: #fff;
  border-radius: 10px;
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
}
.Search--header-container .header--div h6 {
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.search--result h5 {
  color: #4c4c4c;
  font-family: Roboto;
  font-size: 31.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 128.205% */
}
.search--result h5 span {
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 31.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.search--result .video--container {
  border-radius: 12px;
  padding: 15px 40px;
}
@media (max-width: 450px) {
  .search--result .video--container {
    padding: 0px;
  }
}
.search--result .video--container h6 {
  color: #323232;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 28.6px */
}
.search--result .video--container p {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.search--result .video--container .Search--videoCard {
  border-radius: 20px;
  background: #fff;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.search--result .video--container .Search--videoCard h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}
.search--result .video--container .Search--videoCard p {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.search--result .video--container .Search--videoCard .clubDiv {
  border-radius: 24px;
  border: 1px solid #73589b;
  background: linear-gradient(0deg, #73589b 0%, #73589b 100%), #fff;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}
.search--result .video--container .Search--videoCard .article-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.search--result .video--container .Search--videoCard .video-duration {
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 12px;
}
.search--result .video--container .Search--videoCard .video-playBtn {
  position: absolute;
  top: 29%;
  left: 45%;
}
.search--result .video--container .Search--videoCard img {
  height: 205px;
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
}
.search--result .video--container .Search--videoCard .article-img {
  width: 112px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 4px;
}
.search--result .video--container .Search--articleCard {
  border-radius: 20px;
  background: #fff;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.search--result .video--container .Search--articleCard h6 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}
.search--result .video--container .Search--articleCard p {
  color: #707070;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.search--result .video--container .Search--articleCard .clubDiv {
  border-radius: 24px;
  border: 1px solid #73589b;
  background: linear-gradient(0deg, #73589b 0%, #73589b 100%), #fff;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}
.search--result .video--container .Search--articleCard .article-duration {
  position: absolute;
  top: 4%;
  right: 4%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(41.5499992371px);
          backdrop-filter: blur(41.5499992371px);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 10px;
}
.search--result .video--container .Search--articleCard img {
  height: 205px;
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
}
.search--result .video--container .Search--articleCard .article-img {
  width: 112px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 4px;
}
.search--result .video--container .Search--audioCard {
  border-radius: 9.143px;
  border: 0.457px solid #c5c5c5;
  background: #fff;
}
.search--result .video--container .Search--audioCard img {
  width: 112px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 4px;
}
.search--result .video--container .Search--audioCard h6 {
  color: #080807;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.299%; /* 18.768px */
}
.search--result .video--container .Search--audioCard .clubDiv {
  border-radius: 24px;
  border: 1px solid #73589b;
  background: linear-gradient(0deg, #73589b 0%, #73589b 100%), #fff;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}
.search--result .video--container .Search--audioCard p {
  color: #667085;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.search--result .video--container .Search--expertCard {
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 1.84px 3.68px 29.44px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
}
.search--result .video--container .Search--expertCard .expert-img {
  width: 112px;
  height: 112px;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 10px;
}
.search--result .video--container .Search--expertCard .expertDetail p span {
  color: #38ab45;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.24px; /* 157.143% */
}
.search--result .video--container .Search--expertCard .expertDetail h5 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.search--result .video--container .Search--expertCard .expertDetail p {
  color: rgba(0, 0, 0, 0.75);
  font-family: Inter;
  font-size: 12.88px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  white-space: nowrap;
}
.search--result .video--container .Search--expertCard .expertDetail h6 {
  color: #000;
  font-family: Inter;
  font-size: 12.88px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.search--result .video--container .Search--expertCard .nextAvailability {
  padding-top: 12px;
  border-top: 1px solid var(--Colors-Border-border-disabled_subtle, #eaecf0);
}
.search--result .video--container .Search--expertCard .nextAvailability p {
  color: #73589b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.search--result .video--container .Search--expertCard .nextAvailability button {
  border-radius: 24px;
  background: rgba(115, 88, 155, 0.1);
  color: #73589b;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  border: none;
}
.search--result .video--container .Search--expertCard .nextAvailability button:hover {
  border-radius: 24px;
  background: #73589b;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.search--result .video--container .Search--expertCard .expert--buttonGroup button {
  border-radius: 5px;
  background: #38ab45;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
}
.search--result .video--container .Search--expertCard .expert--buttonGroup .ShareButton {
  border-radius: 5px;
  border: 1px solid #38ab45;
  color: #38ab45;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  background: transparent;
}
.search--result .video--container .Search--ProgramCard {
  border: 1px solid rgb(218, 218, 218);
  height: 100%;
  border-radius: 8px;
  box-shadow: 1.84px 3.68px 29.44px 0px rgba(0, 0, 0, 0.08);
}
.search--result .video--container .Search--ProgramCard .program-img {
  max-width: 112px;
  min-width: 112px;
  max-height: 112px;
  min-height: 112px;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 4px;
  border-radius: 10px;
}
.search--result .video--container .Search--ProgramCard h6 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.352px;
}
.search--result .video--container .Search--ProgramCard .clubDiv {
  border-radius: 24px;
  border: 1px solid #73589b;
  background: linear-gradient(0deg, #73589b 0%, #73589b 100%), #fff;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
  text-decoration: none;
}
.search--result .video--container .Search--ProgramCard p {
  color: #667085;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.search--result .video--container .Search--ProgramCard .duration-Div {
  background-color: rgb(204, 182, 236);
  border-radius: 5px;
  padding: 2px 5px;
}
.search--result .video--container .Search--ProgramCard .program--buttonGroup button {
  border-radius: 5px;
  background: #38ab45;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
}
.search--result .video--container .Search--ProgramCard .program--buttonGroup .ShareButton {
  border-radius: 5px;
  border: 1px solid #38ab45;
  color: #38ab45;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  background: transparent;
}/*# sourceMappingURL=Search.css.map */