.disclaimer--popup {
  h4 {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 35.943px; /* 128.366% */
  }

  .modal-content {
    border-radius: 16px;
    background: #fff !important;
  }

  p {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }

  .disclaimer-points {
    border-radius: 16px;
    border: 2px solid #ebebeb;
    height: 40vh;
    overflow-y: scroll;
    padding: 10px 15px 20px;
  }

  .modal-dialog {
    height: 60vh !important;
  }

  .modal-body {
    button {
      border-radius: 10px;
      background: var(--button-primary-color) !important;
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.018px;
      display: block;
      margin: 0 auto;
      border: none;
    }

    .MuiFormControlLabel-label {
      span {
        color: #696969;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        a {
          color: #696969;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration-line: underline;
        }
      }
    }
  }

  button {
    border-radius: 10px;
    background: var(--button-primary-color) !important;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.018px;
    display: block;
    margin: 0 auto;
  }

  .disclaimer--checkbox-label {
    color: #696969;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    a {
      color: #696969;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }
}

.otp--popup {
  .modal-content {
    border-radius: 20px;
    background: #fff !important;
  }

  .modal-body {
    padding: 40px 30px;

    .otp--header {
      h3 {
        color: #000;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    p {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%; /* 24px */

      span {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root::before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    }

    .MuiInput-input {
      color: #000;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: 0.02px;
    }

    .otp--resend {
      p {
        color: #767575;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.016px;
      }
    }
    .otp--continue-btn {
      background: var(--button-primary-color) !important;
    }
  }
}

.signup--section2 {
  background: black;

  .section2-container {
    width: 80%;
    height: 90%;
    display: block;
    margin: auto;
    border-radius: 16px;
    background: #fff;
    padding: 5% 10%;

    h5 {
      color: #000;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 162%; /* 38.88px */
    }

    h6 {
      color: #767676;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 162%;
      text-align: center;
    }

    .clubs--container {
      div {
        position: relative;

        img {
          border: 1px solid #ccc;
        }

        p {
          color: #fff;
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          z-index: 999;
          bottom: 10px;
          position: absolute;
        }

        .Club--slide {
          margin: 10px !important;
        }

        // .swiper-button-next::after, .swiper-button-prev::after{
        //     font-family: none;
        // }

        .swiper-button-next::after,
        .swiper-rtl .swiper-button-prev::after {
          content: url(/public/assets/img/kareify/left.png) !important;
        }

        .swiper-button-prev::after,
        .swiper-rtl .swiper-button-next::after {
          content: url(/public/assets/img/kareify/right.png) !important;
        }
      }
    }
  }
}

.kareify--SignUp {
  //   height: 70vh;
  background-image: url("/public/assets/img/kareify/signUp_bg.webp");
  background-size: 100% auto;
  background-repeat: no-repeat;

  @media screen and (max-width: 500px) {
    background-size: 100% 100%;

    .signUp-container {
      padding: 50px 0;
      // background: linear-gradient(0deg, #000 19.26%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(
        0deg,
        #000 19.26%,
        rgba(0, 0, 0, 0.4) 100%
      ) !important;
    }
  }

  .welcome-text {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 49.4px */
    text-align: center;
  }

  .welcome-text-md {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 48px;
    font-style: italic;
    font-weight: 500;
    line-height: 140%; /* 89.6px */
  }

  .signUp-container {
    padding: 50px 0;
    background: linear-gradient(0deg, #000 -9.26%, rgba(0, 0, 0, 0.3) 100%);
  }

  .close-btn {
    margin: 0px 50px 0px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 17.354px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.971px; /* 103.554% */
  }

  @media only screen and (max-width: 500px) {
    .SignIn--box {
      margin-right: 0px !important;
      background: #ffffff !important;
    }

    .kareify--SignIn h2 {
      margin-right: 0px !important;
    }
  }
  .SignIn--box {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.75);
    padding: 30px 0;
    margin-right: 90px;

    // .social--login {
    //   .nsm7Bb-HzV7m-LgbsSe-BPrWId {
    //     display: none !important;
    //   }

    //   .nsm7Bb-HzV7m-LgbsSe-Bz112c {
    //     margin-right: 0px !important;
    //   }
    // }

    input {
      border-radius: 9px !important;
      border: 1px solid #cecece !important;
      background: #fff !important;
      height: 56px !important;
      padding-left: 15px;
    }

    input::placeholder {
      // padding: 0 15px !important;
      color: #989898;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .form-select {
      border-radius: 9px !important;
      border: 1px solid #cecece !important;
      background: #fff !important;
      height: 56px !important;
    }

    .form-select::placeholder {
      color: #989898;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .form-select:first-child {
      color: #989898;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    // .disabled {
    //   color: #989898;
    //   font-family: "Roboto", sans-serif;
    //   font-size: 20px;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: normal;
    // }

    h6 {
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.68px;
    }

    //   span {
    //     color: var(--primary-color);
    //     font-family: "Roboto", sans-serif;
    //     font-size: 48px;
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: normal;
    //     letter-spacing: 1.68px;
    //   }

    h3 {
      color: #000;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
      padding: 0 !important;
    }

    .horizontal--line {
      hr {
        border-bottom: 2px solid #b0b0b0;
        width: 100%;
      }

      p {
        color: #626262;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    p {
      color: #585858;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: var(--primary-color);
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    button {
      border-radius: 10px;
      background: var(--button-primary-color);
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 10px;
    }

    .MuiInputBase-input {
      background: #fff;
      border-radius: 10px !important;
    }
  }
}

// .nsm7Bb-HzV7m-LgbsSe-BPrWId {
//   display: none !important;
// }

// .nsm7Bb-HzV7m-LgbsSe-Bz112c {
//   margin-right: 0px !important;
// }

.otp-container {
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 20px;

  input {
    height: 70px;
    width: 50px !important;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    border: none !important;
    border-bottom: 2px solid #000 !important;
  }
}

.kareify--SignIn .Cerified--desc {
  width: 70%;
  color: #fff;
  margin: 15px auto;
  text-align: center;
  font-size: 1rem;
  @media only screen and (max-width: 500px) {
    width: 95%;
    font-size: 0.65rem;
  }
}
