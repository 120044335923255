.search_bar:focus {
  border: none;
  outline: none;
}

.custom-menu-items {
  padding-left: 0;
}

.custom-menu-item {
  font-size: 1rem;
  list-style: none;

  cursor: pointer;

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
}

.custom-menu-item:hover {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.menu-icon {
  margin-right: 10px; /* Adjust the spacing between the icon and text */
}

.menu-text {
  display: inline-block;
  vertical-align: middle; /* Adjust the vertical alignment as needed */
}

.icons {
  display: none;
}

.options_tabs:hover .icons {
  display: inline;
}

.userdashboard--Navbar {
  box-shadow: 0 0 20px 0 rgba(63, 63, 63, 0.1);
  z-index: 999;
  .container-fluid {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin: 0 !important;
      padding: 0 20px;
      gap: 20px;
      & > div {
        flex: 1;
        width: 100%;
      }
    }
  }
  .userdashboard--Search {
    border-radius: 10px;
    @media only screen and (max-width: 600px) {
      display: none !important;
    }
  }
  .userdashboard--Search__BTN {
    @media only screen and (max-width: 600px) {
      display: block !important;
    }
  }
}

.listdrop {
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/** cards styling**/
/* SoftUICardLayout.css */

/*
  .dashboard_card::-webkit-scrollbar {
    display: none;
  }
  
  .dashboard_card {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  }
 */

.left-card,
.cardsl {
  background-color: #fefefe;
  border-radius: 10px;
}

.rectangle-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(10, 10, 10, 0.1);
}

.scroll-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(10, 10, 10, 0.155);
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  width: 100%;
}

.assessment-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(10, 10, 10, 0.155);
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 200px;
  max-width: 200px;
  width: 100%;

  .title-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.cardfooter {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scroll-card img {
  width: 65px;
  height: 65px;

  /* margin-bottom: 10px; */
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  // scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0px 0 10px;
}

// .scroll-container::-webkit-scrollbar {
//   width: 0;
//   height: 0;
// }

.title1,
.title2 {
  font-size: 15px;
  font-weight: bold;
}

.button {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;

  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.inputfeild:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.inputrow {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.inputrow {
  border: 1px solid #610c9f;
}

.userDashboard--moods,
.userDashboard--appointments,
.userDashboard--stella {
  position: relative;
  padding: 10px;
  flex: 1;
  padding: 15px 18px;
  img.rounded {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px !important;
  }
  .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
  }
}
.userDashboard--stella {
  flex: 1.25;
  .userDashboard--stella__items {
    @media only screen and (max-width: 600px) {
      span {
        flex: 1;
      }
    }
  }
}

.userDashboard--cardsContainer {
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.professionalHelp--Card {
  @media only screen and (max-width: 600px) {
    .rectangle-card {
      & > div {
        justify-content: center;
        img {
          margin: auto;
        }
        h6 {
          text-align: center;
        }
      }
    }
  }
}

.userdashboardDrawerClosed {
  main {
    width: calc(100vw - 81px);
  }
}

.userdashboardDrawerOpen {
  main {
    width: calc(100vw - 256px);
  }
}

.Policy--Page {
  background-color: rgb(244, 244, 244);
  min-height: 100vh;
  padding: 50px;
  .Exisiting-Policy--Container {
    max-width: 1080px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin: auto;
    min-height: 100%;

    .Exisitng-Policy--Container__Left {
      grid-column: span 1 / span 1;
      background-color: white;
      padding: 20px;
      min-height: 100%;
      border-radius: 10px;
      gap: 20px;

      .Insurance-Detail-Card {
        min-height: 148px;
        border-radius: 12px;
        position: relative;
        padding: 0px;
        margin: 15px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }

        .bg-img {
          border-radius: 12px;
          min-height: 165px;
          width: 100%;
        }

        .card-content {
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex-shrink: 0;
          border-radius: 12px;
          line-height: large;
          height: 100%;
          padding: 20px;
          color: white;
          width: 100%;

          .insurance-name {
            // color: #101828;
            color: white;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-left: 5px; // Add margin to separate the image
            }
          }

          .verified {
            // color: #17b26a;
            color: white;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
          }

          .member-id {
            color: #101828;
            color: white;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            margin-top: 5px; // Add margin for separation
          }

          .policy-number {
            color: #101828;
            color: white;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.84px;
            text-transform: uppercase;
          }
        }
      }
    }
    .Exisitng-Policy--Container__Right {
      grid-column: span 2 / span 2;
      background-color: white;
      min-height: 100%;
      max-height: 100%;
      border-radius: 10px;
      padding: 20px;
      .insurance-details {
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        padding: 15px;
        width: 100%;
        background: #eaecf0;
        img {
          width: 79px;
          height: 79px;
          border-radius: 10px;
          background-color: white;
        }
        .card-content {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .insurance-name {
            color: var(--colors-text-text-primary-900, #101828);
            text-shadow: 0px 1px 1px rgba(66, 155, 92, 0.1);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
          }

          .verified {
            color: var(--colors-text-text-secondary-700, #344054);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
          }

          .member-id,
          .policy-number {
            color: var(--colors-text-text-tertiary-600, #475467);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            span {
              color: var(--colors-text-text-primary-900, #101828);
              font-family: Roboto;
              font-size: 14px;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }
      }

      .claim-history-container {
        margin: 20px 0;
        min-height: 50vh;
        .claim-history-row {
          padding: 10px;
          border-bottom: 1px solid #eaecf0;
          background: #fff;
          display: flex;
          justify-content: space-between;
          .expert-img {
            width: 45px;
            height: 45px;
            border-radius: 6px;
            object-fit: cover;
          }
          .history-details {
            p {
              color: var(--Text-text-primary, #080807);
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
            }
            .expert-name {
              color: var(--colors-text-text-secondary-700, #344054);
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
            }
            .session-date {
              color: var(--colors-text-text-quaternary-500, #667085);
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .hover-icon {
            opacity: 0;
            transition: opacity 0.3s ease;
          }
          &:hover {
            background-color: rgba(234, 236, 240, 0.6);
            .hover-icon {
              opacity: 1; /* Make the icon visible */
            }
          }
        }
      }
    }
  }

  .Add-policy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 30px;

    .Add-policy-container__Left {
      background-color: white;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center horizontally */
      height: 100%;
      padding: 20px;
    }

    .Add-policy-container__Right {
      display: flex;
      height: 100%;
      justify-content: center;
      flex: 2;
      border-radius: 0px 10px 10px 0px;
      background-color: #eaecf0;
      padding: 30px;
      .add-policy-img {
        width: 100%;
        min-height: 346px;
        object-fit: contain;
        border-radius: 10px;
      }
    }
  }
}

.Company-list-modal {
  .MuiPaper-root {
    min-width: 500px !important;
    max-height: 600px !important;
  }
}

.Claim-History-Modal {
  .MuiPaper-root {
    min-width: 950px !important;
    max-height: 660px !important;
    .MuiDialogContent-root {
      padding: 0;
      .Modal-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 100%;
        .session-details-container {
          height: 100%;
          padding: 20px;

          .Expert--Info__Header {
            padding: 10px;
            img {
              width: 108px;
              height: auto;
              aspect-ratio: 1/1;
              object-fit: cover;
              border-radius: 10px;
            }

            h3 {
              font-size: 1.5rem;
              font-weight: 600 !important;
              margin-bottom: 0 !important;
            }
            p {
              color: #344054;
              font-size: 1.15rem;
            }
          }
          .Info--Grid {
            padding: 10px;
            gap: 10px;
            font-size: 16px;
            font-weight: 400;
            border-radius: 10px;
            background: rgba(152, 162, 179, 0.1);
            color: #000 !important ;
            .text {
              color: #000;
            }
          }
        }
        .bill-container {
          background-color: #d0d5dd;
          padding: 30px;

          .background {
            background-color: #fff;
            padding: 20px;
            border-radius: 12px;
            .receipt {
              border-radius: 10px;
              background: rgba(152, 162, 179, 0.1);
              width: 100%;
              padding: 10px;
              div {
                color: #000 !important;
              }
            }
          }
        }
      }
    }
  }
}

.Add-Policy-Modal {
  .MuiPaper-root {
    max-width: 500px !important;
    max-height: 600px !important;
  }
}

.course_popup_conform_button {
  background-color: #73589b;
  display: flex;
  margin: 16px auto 0px;
  color: #ffffff;
  font-weight: 600;
  padding: 8px 25px;
  border-radius: 5px;
  border: none;
}

.course_popup_conform_text {
  text-align: left;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 25px;
  color: #000000;
  font-size: 18px;
}
