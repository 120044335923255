.sections-section {
  height: 75rem;
}

.sections-bg-img {
  /* position: absolute; */
  width: 100%;
  height: 22.625rem;
  min-height: 20rem;
  background-image: url("/public/assets/img/course_internal_journey/sections/Card_FC.png");
  position: absolute;
  top: 0;
  left: 0;
  /* margin-top: -10rem; */
}

.sections-container {
  position: relative;
  width: 100%;
  height: max-content;

  border-radius: 1.25rem;
  background: #fff;
  padding: 3rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  /* z-index: 10;
  position: absolute; */
}
.sections--content {
  position: relative;
  padding: 50px;
  width: 100%;
}

.sections-heading {
  color: #6d6d6d;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
}

.sections-progress {
  color: #6d6d6d;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem; /* 127.928% */
  letter-spacing: 0.03125rem;
  margin: 2rem 0;
}

.sections-name {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem; /* 67.727% */
  margin: 1rem 0;
}

.sections-desc {
  width: 100%;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.0225rem;
  margin: 1rem 0;
}

.sections-video-img {
  width: 100%;
  /* height: 27.375rem; */
  justify-content: center;
  align-items: center;
}

.sections-video-img .AudioURL audio {
  height: 80px !important;
}

.sections-video-img .AudioURL {
  height: 150px !important;
  display: flex;
  align-items: center;
}

.sections-video-img .VideoURL {
  height: auto !important;
}

.sections-video-img > div {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
}

.sections-footer {
  display: flex;
  margin: 1rem 10px 1rem auto;
  width: max-content;
}

.sections-transcript {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.0225rem;
  display: flex;
  gap: 0.5rem;
  margin: -2rem 0 -1rem -4rem;
}

.transcript-img {
  width: 1.57rem;
  height: 1.25rem;
  margin-top: 0.2rem;
}

.transcript-text {
  display: flex;
  flex-direction: column;
  width: 8.5625rem;
}

.transcript-line {
  width: 8.25rem;
  height: 0.0625rem;
  background: #000;
}

.transcript-bookmark {
  width: 1.2rem;
  height: 1.2rem;
}

.sections-btn {
  margin-top: 1rem;
  /* margin-left: -3rem; */
  display: flex;
  height: 2rem;
  padding: 0.625rem 0.25rem;
  justify-content: space-between;
  gap: 0.625rem;
}
.sections--title {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
@media (max-width: 500px) {
  .sections-sidebar {
    display: none;
  }
  .sections-section {
    background-image: url("/public/assets/img/course_internal_journey/sections/Card_FC.png");
    height: 65rem;
  }
  .sections-bg-img {
    height: 230px;
    min-height: 230px;
    background-size: cover;
    display: none;
  }
  .sections-container {
    width: 100%;
    height: max-content;
    padding: 0;
    /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12); */
    box-shadow: none;
    z-index: 10;
    margin: 0;
  }
  .sections-progress {
    margin: 1rem 0;
  }
  .sections-heading {
    display: block;
    font-size: 0.9rem;
    line-height: 150%;
    margin-top: 20px;
  }
  .sections-name {
    font-size: 1rem;
    margin: 0;
    /* border-bottom: 0.0625rem solid #e0e0e0; */
    width: 100%;
  }
  .sections-desc {
    width: 100%;
  }
  .sections-video-img {
    /* display: none; */
    margin: 0;
  }
  .sections-footer {
    gap: 8rem;
    margin-left: 0;
  }
  .transcript-bookmark {
    margin: 0;
  }
  .sections--content {
    padding: 1rem;
    width: 100%;
  }
}
