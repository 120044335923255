.Booking--Page {
  background-color: #f5f5f5;
  padding: 50px;
  min-height: 50vh;
  .Booking--Container {
    max-width: 1080px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin: auto;
    & > div {
      box-shadow: 0px 10px 20px 0px #babcc61a;
      padding: 20px;
      border-radius: 20px;
      background-color: #fff;
    }
    .Booking--Container__Left {
      grid-column: span 1 / span 1;
      .Expert--Info {
        & > * + * {
          margin-top: 1.25rem; /* 20px */
        }
        .Expert--Info__Header {
          padding: 10px;
          img {
            width: 108px;
            height: auto;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 10px;
          }

          h3 {
            font-size: 1.5rem;
            font-weight: 600 !important;
            margin-bottom: 0 !important;
          }
          p {
            color: #344054;
            font-size: 1.15rem;
          }
        }

        .Expert--Info__Content {
          padding: 10px;
          & > * + * {
            margin-top: 0.75rem;
          }
          & > div:not(.Content--Qualifications) {
            color: #667085;
          }
          .Content--Qualifications {
            p {
              span {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .Booking--Container__Right {
      grid-column: span 2 / span 2;
      min-height: 70vh;

      & > * + * {
        margin-top: 1.5rem;
      }
      .Booking--Info {
        background-color: var(--primary-color);
        padding: 20px 15px;
        border-radius: 15px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Info--Content {
          & > * + * {
            margin-top: 1.15rem;
          }
          h6 {
            color: #fff;
            font-size: 1.15rem;
            font-weight: 600;
          }
          .Info--Grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 10px;
            font-size: 1rem;
            font-weight: 500;
          }
        }

        .Edit--Button {
          background: #ffffff33;
          padding: 5px;
          border-radius: 10px;
          border: none;
          color: #fff;
        }
      }

      .Booking--Form {
        &__heading {
          color: #080807;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Policy--Box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 140px;
        h6 {
          color: #080807;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

.Add-Policy-Modal {
  .MuiPaper-root {
    max-width: 500px !important;
    max-height: 600px !important;
  }
}

.insurance-card {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  // gap: 20px;
  margin-top: 20px;
}

.Insurance-Detail-Card {
  min-height: 155px;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  flex: 0 0 calc(50% - 10px);
  &:nth-child(2n) {
    margin-right: 0;
  }

  .bg-img {
    border-radius: 12px;
    min-height: 165px;
    width: 100%;
  }

  .card-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 12px;
    line-height: large;
    height: 100%;
    padding: 20px;
    color: white;
    width: 100%;

    .insurance-name {
      // color: #101828;
      color: white;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-left: 5px; // Add margin to separate the image
      }
    }

    .verified {
      // color: #17b26a;
      color: white;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .member-id {
      color: #101828;
      color: white;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      margin-top: 5px; // Add margin for separation
    }

    .policy-number {
      color: #101828;
      color: white;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.84px;
      text-transform: uppercase;
    }
  }
}

.Policy-Not-Found-Modal {
  .MuiPaper-root {
    max-width: 500px !important;
  }

  .policy-not-found--text {
    color: var(--colors-text-text-secondary-700, #344054);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
}

.Bill--Container {
  p {
    color: var(--Text-text-primary, #080807);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
  }
  .bill-key {
    color: var(--colors-text-text-secondary-700, #344054);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
  }
  .bill-value {
    color: var(--colors-text-text-primary-900, #101828);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
  }
  .disclaimer-text {
    color: var(--colors-text-text-secondary-700, #344054);
    font-family: Roboto;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    .read-more-text {
      color: var(--colors-text-text-secondary-700, #344054);
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: underline;
    }
  }
}

.Read-More-Modal {
  
  .MuiPaper-root {
    max-width: 500px !important;
    overflow-x: hidden;
  }
  // .MuiTypography-root{
  //   position: relative;
  //   // z-index: -9;
  // }
  .close-button {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 100;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(59, 62, 71, 0.1);
    display: flex;
  justify-content: center;
  align-items: center;
  }
  div > p {
    color: #101828;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px; 
  }
}

.Booking--Status {
  display: flex;
  flex-direction: column;
  align-items: center;
  .status-container {
    width: 360px;
    padding: 10px;
    margin: 50px 0 30px 0;
    .card-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      
      .green-tick {
        width: 60px;
        height: 60px;
        text-align: center;
      }
      h5 {
        color: var(--Text-text-primary, #080807);
        text-align: center;
        font-family: Rubik;
        font-size: 24px;
        font-weight: 500;
        line-height: 35px; /* 145.833% */
      }
      p {
        width: 85%;
        color: var(--Text-text-secondary, #252422);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        text-align: center;
      }
    }

    .appointment-confirm-card {
      border-radius: var(--radius-md, 8px);
      border: 1px solid #e3e3e3;
      width: 100%;
      padding: 15px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .heading {
        color: var(--Text-text-placeholder, #807c75);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px; /* 143.75% */
      }
      .expert-image {
        height: 150px;
        width: 90%;
        border-radius: 8px;
        object-fit: cover;
        aspect-ratio: 1/1;
      }
      .expert-name {
        color: #000;
        font-family: Helvetica;
        font-size: 14.973px;
        font-style: normal;
        font-weight: 700;
        line-height: 22.46px; /* 150% */
        letter-spacing: 0.468px;
      }
      .expert-exp {
        color: #757575;
        font-family: Helvetica;
        font-size: 13.684px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.46px; /* 164.127% */
        letter-spacing: 0.468px;
      }
      .session-info-table {
        table {
          width: 100%;
          border-collapse: collapse;
        }
        td {
          border: none;
          padding: 5px 0;
        }
        .session-label {
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .session-details {
          color: #666;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.loader-booking {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #73589b; /* Your desired background color */
  z-index: 1000;
}

.expert-profession-badge {
  background-color: #73589B1A;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  padding: 0px 10px;
  text-align: center;
  color: #73589B;
}
