.kareify-logo-container img {
  width: 326px;
}

.kareify-headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-dropdown-item {
  background: #73589b !important;
  padding: 10px 0px !important;
}

.header-title .dropdown-menu {
  padding: 0px !important;
}

.kareify-Menu-header-text {
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  color: #0e0f0c !important;
  padding: 10px !important;
}

.kareify-Menu-header-text:hover {
  color: #73589b !important;
}

.kareify-Menu-header-text a {
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  color: #0e0f0c !important;
  padding: 10px 20px !important;
  text-align: center;
}

.kareify-Menu-header-text a:hover {
  color: #73589b !important;
}

/* .kareify-Menu-header-text ::after{
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 0px;
      background-color: #000;
      transition: all ease 1s;
  }
  
  .kareify-Menu-header-text :hover ::after{
      width: 100%;
  } */

.header-offcanvas {
  height: fit-content !important;
  font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 500px) {
  .header-offcanvas .offcanvas-body {
    background-color: #FFF !important;
    padding: 10px 0 !important;
    color: #080807 !important;
    font-weight: 600;
  }
  .header-offcanvas .offcanvas-body > div{
    border-top: 1px solid #EAECF0;
  }
 
}

.kareify-headerSearch::placeholder {
  color: #73589b !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.kareify-headerSearch:focus {
  box-shadow: none !important;
}

.kareify-Menu-header-text {
  /* Your existing styles for Nav.Link */
  color: inherit;
  /* Ensure default color */
  text-decoration: none;
  /* Remove default underline */
  position: relative;
  /* Position for pseudo-element */
}

.kareify-Menu-header-text::after {
  content: "";
  position: absolute;
  bottom: -2px;
  /* Adjust the distance from the text */
  left: 0;
  width: 0;
  /* Initially set to 0 */
  height: 4px;
  /* Thickness of the underline */
  background-color: #73589b;
  /* Color of the underline */
  transition: width 0.3s ease;
  /* Smooth width transition */
}

.kareify-Menu-header-text:hover::after {
  width: 100%;
  /* Increase width on hover */
}

.kareify-menu-companySize-text {
  background: #f9f4ff;
  color: #73589b !important;
  font-weight: 500 !important;
  padding-bottom: 9px !important;
  pointer-events: none;
  padding: 10px 20px !important;
  text-align: center;
}

.small-business-text {
  color: #686968;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
}

@media screen and (max-width: 500px) {
  .dropdown-header > .dropdown-menu {
    width: 93vw !important;
  }
}

.kareify-Menu-header-text .show {
  z-index: 999;
}