.UserDashboard--Chat .sentChat .messagesArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.UserDashboard--Chat .sentChat .messagesArea > div {
  background: rgba(115, 88, 155, 0.09);
  border-radius: 10px;
  border-bottom-right-radius: 0;
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.21px;
  line-height: 145.023%;
  margin-left: auto;
  padding: 10px;
}
.UserDashboard--Chat .receivedChat .messagesArea > div {
  background: #f5f5f5;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.21px;
  line-height: 145.023%;
  margin-right: auto;
  padding: 10px;
}
.UserDashboard--Chat .SendText--Container {
  background-color: rgba(115, 88, 155, 0.09);
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.noAppointmentContainer {
  height: 40vh;
}/*# sourceMappingURL=UserDashboardChat.css.map */