.Booking--Page {
  background-color: #f5f5f5;
  padding: 50px;
  min-height: 50vh;
}
.Booking--Page .Booking--Container {
  max-width: 1080px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin: auto;
}
.Booking--Page .Booking--Container > div {
  box-shadow: 0px 10px 20px 0px rgba(186, 188, 198, 0.1019607843);
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
}
.Booking--Page .Booking--Container .Booking--Container__Left {
  grid-column: span 1/span 1;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info > * + * {
  margin-top: 1.25rem; /* 20px */
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Header {
  padding: 10px;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Header img {
  width: 108px;
  height: auto;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Header h3 {
  font-size: 1.5rem;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Header p {
  color: #344054;
  font-size: 1.15rem;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Content {
  padding: 10px;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Content > * + * {
  margin-top: 0.75rem;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Content > div:not(.Content--Qualifications) {
  color: #667085;
}
.Booking--Page .Booking--Container .Booking--Container__Left .Expert--Info .Expert--Info__Content .Content--Qualifications p span {
  font-weight: 500;
}
.Booking--Page .Booking--Container .Booking--Container__Right {
  grid-column: span 2/span 2;
  min-height: 70vh;
}
.Booking--Page .Booking--Container .Booking--Container__Right > * + * {
  margin-top: 1.5rem;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Booking--Info {
  background-color: var(--primary-color);
  padding: 20px 15px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Booking--Info .Info--Content > * + * {
  margin-top: 1.15rem;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Booking--Info .Info--Content h6 {
  color: #fff;
  font-size: 1.15rem;
  font-weight: 600;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Booking--Info .Info--Content .Info--Grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  font-size: 1rem;
  font-weight: 500;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Booking--Info .Edit--Button {
  background: rgba(255, 255, 255, 0.2);
  padding: 5px;
  border-radius: 10px;
  border: none;
  color: #fff;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Booking--Form__heading {
  color: #080807;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Policy--Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 140px;
}
.Booking--Page .Booking--Container .Booking--Container__Right .Policy--Box h6 {
  color: #080807;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Add-Policy-Modal .MuiPaper-root {
  max-width: 500px !important;
  max-height: 600px !important;
}

.insurance-card {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.Insurance-Detail-Card {
  min-height: 155px;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  flex: 0 0 calc(50% - 10px);
}
.Insurance-Detail-Card:nth-child(2n) {
  margin-right: 0;
}
.Insurance-Detail-Card .bg-img {
  border-radius: 12px;
  min-height: 165px;
  width: 100%;
}
.Insurance-Detail-Card .card-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  line-height: large;
  height: 100%;
  padding: 20px;
  color: white;
  width: 100%;
}
.Insurance-Detail-Card .card-content .insurance-name {
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.Insurance-Detail-Card .card-content .insurance-name img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.Insurance-Detail-Card .card-content .verified {
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.Insurance-Detail-Card .card-content .member-id {
  color: #101828;
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.Insurance-Detail-Card .card-content .policy-number {
  color: #101828;
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}

.Policy-Not-Found-Modal .MuiPaper-root {
  max-width: 500px !important;
}
.Policy-Not-Found-Modal .policy-not-found--text {
  color: var(--colors-text-text-secondary-700, #344054);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.Bill--Container p {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
}
.Bill--Container .bill-key {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
.Bill--Container .bill-value {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
.Bill--Container .disclaimer-text {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.Bill--Container .disclaimer-text .read-more-text {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
}

.Read-More-Modal .MuiPaper-root {
  max-width: 500px !important;
  overflow-x: hidden;
}
.Read-More-Modal .close-button {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(59, 62, 71, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Read-More-Modal div > p {
  color: #101828;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.Booking--Status {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Booking--Status .status-container {
  width: 360px;
  padding: 10px;
  margin: 50px 0 30px 0;
}
.Booking--Status .status-container .card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.Booking--Status .status-container .card-header .green-tick {
  width: 60px;
  height: 60px;
  text-align: center;
}
.Booking--Status .status-container .card-header h5 {
  color: var(--Text-text-primary, #080807);
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  line-height: 35px; /* 145.833% */
}
.Booking--Status .status-container .card-header p {
  width: 85%;
  color: var(--Text-text-secondary, #252422);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-align: center;
}
.Booking--Status .status-container .appointment-confirm-card {
  border-radius: var(--radius-md, 8px);
  border: 1px solid #e3e3e3;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Booking--Status .status-container .appointment-confirm-card .heading {
  color: var(--Text-text-placeholder, #807c75);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 143.75% */
}
.Booking--Status .status-container .appointment-confirm-card .expert-image {
  height: 150px;
  width: 90%;
  border-radius: 8px;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
}
.Booking--Status .status-container .appointment-confirm-card .expert-name {
  color: #000;
  font-family: Helvetica;
  font-size: 14.973px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.46px; /* 150% */
  letter-spacing: 0.468px;
}
.Booking--Status .status-container .appointment-confirm-card .expert-exp {
  color: #757575;
  font-family: Helvetica;
  font-size: 13.684px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.46px; /* 164.127% */
  letter-spacing: 0.468px;
}
.Booking--Status .status-container .appointment-confirm-card .session-info-table table {
  width: 100%;
  border-collapse: collapse;
}
.Booking--Status .status-container .appointment-confirm-card .session-info-table td {
  border: none;
  padding: 5px 0;
}
.Booking--Status .status-container .appointment-confirm-card .session-info-table .session-label {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Booking--Status .status-container .appointment-confirm-card .session-info-table .session-details {
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loader-booking {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #73589b; /* Your desired background color */
  z-index: 1000;
}

.expert-profession-badge {
  background-color: rgba(115, 88, 155, 0.1019607843);
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  padding: 0px 10px;
  text-align: center;
  color: #73589B;
}/*# sourceMappingURL=Booking.css.map */