.kareify--SignIn .close-btn {
  margin: 0px 50px 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.kareify--SignIn h2 {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 17.354px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.971px; /* 103.554% */
}
@media only screen and (max-width: 500px) {
  .kareify--SignIn .SignIn--box {
    margin-right: 0px !important;
    border-radius: 12px;
    background: #fff;
    padding: 20px 0;
  }
  .kareify--SignIn .SignIn--box h6 {
    color: #000 !important;
    text-align: center !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: 1px !important;
    margin-bottom: 0 !important;
  }
  .kareify--SignIn .SignIn--box h6 .diff-clr {
    color: var(--primary-color) !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .kareify--SignIn .SignIn--box h3 {
    color: #000 !important;
    text-align: center !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 0 !important;
  }
  .kareify--SignIn .SignIn--box p {
    color: #585858 !important;
    text-align: center !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-bottom: 0 !important;
  }
  .kareify--SignIn .SignIn--box p .diff-clr {
    color: var(--primary-color) !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-decoration-line: underline !important;
  }
  .kareify--SignIn .kareify--SignIn h2 {
    margin-right: 0px !important;
  }
}
.kareify--SignIn .SignIn--box input {
  border-radius: 9px !important;
  border: 1px solid #cecece !important;
  background: #fff !important;
  height: 56px !important;
  padding: 0 15px !important;
}
@media only screen and (max-width: 500px) {
  .kareify--SignIn .SignIn--box input {
    width: 75% !important;
  }
}
.kareify--SignIn .SignIn--box input::-moz-placeholder {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignIn .SignIn--box input::placeholder {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignIn .SignIn--box h6 {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.68px;
  margin-bottom: 0;
}
.kareify--SignIn .SignIn--box h3 {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kareify--SignIn .SignIn--box .horizontal--line hr {
  border-bottom: 2px solid #b0b0b0;
  width: 100%;
}
.kareify--SignIn .SignIn--box .horizontal--line p {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kareify--SignIn .SignIn--box p {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignIn .SignIn--box p span {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}
.kareify--SignIn .SignIn--box button {
  border-radius: 10px;
  background: var(--button-primary-color);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.018px;
  padding: 16px 25px;
}
@media only screen and (max-width: 500px) {
  .kareify--SignIn .SignIn--box button {
    width: 75% !important;
  }
}
.kareify--SignIn .SignIn--box button:disabled {
  background: #bcafcf !important;
  opacity: 1 !important;
}
.kareify--SignIn .SignIn--box button:active {
  background: var(--button-primary-color);
}
.kareify--SignIn .SignIn--box button:active:focus {
  box-shadow: none;
}
.kareify--SignIn .SignIn--box .MuiInputBase-input {
  background: #fff;
  border-radius: 10px !important;
}

.details--popup .modal-content {
  border-radius: 20px;
  background: #fff;
  padding: 25px 20px;
}
.details--popup .modal-content h5 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.details--popup .modal-content .input-section {
  margin: 20px 5px 0;
}
.details--popup .modal-content button {
  background: var(--button-primary-color);
  border: 0;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.018px;
}/*# sourceMappingURL=SignIn.css.map */