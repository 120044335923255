.disclaimer--popup h4 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 35.943px; /* 128.366% */
}
.disclaimer--popup .modal-content {
  border-radius: 16px;
  background: #fff !important;
}
.disclaimer--popup p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
.disclaimer--popup .disclaimer-points {
  border-radius: 16px;
  border: 2px solid #ebebeb;
  height: 40vh;
  overflow-y: scroll;
  padding: 10px 15px 20px;
}
.disclaimer--popup .modal-dialog {
  height: 60vh !important;
}
.disclaimer--popup .modal-body button {
  border-radius: 10px;
  background: var(--button-primary-color) !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.018px;
  display: block;
  margin: 0 auto;
  border: none;
}
.disclaimer--popup .modal-body .MuiFormControlLabel-label span {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.disclaimer--popup .modal-body .MuiFormControlLabel-label span a {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
.disclaimer--popup button {
  border-radius: 10px;
  background: var(--button-primary-color) !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.018px;
  display: block;
  margin: 0 auto;
}
.disclaimer--popup .disclaimer--checkbox-label {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.disclaimer--popup .disclaimer--checkbox-label a {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

.otp--popup .modal-content {
  border-radius: 20px;
  background: #fff !important;
}
.otp--popup .modal-body {
  padding: 40px 30px;
}
.otp--popup .modal-body .otp--header h3 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.otp--popup .modal-body p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 24px */
}
.otp--popup .modal-body p span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.otp--popup .modal-body .css-v4u5dn-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.otp--popup .modal-body .MuiInput-input {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.02px;
}
.otp--popup .modal-body .otp--resend p {
  color: #767575;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.016px;
}
.otp--popup .modal-body .otp--continue-btn {
  background: var(--button-primary-color) !important;
}

.signup--section2 {
  background: black;
}
.signup--section2 .section2-container {
  width: 80%;
  height: 90%;
  display: block;
  margin: auto;
  border-radius: 16px;
  background: #fff;
  padding: 5% 10%;
}
.signup--section2 .section2-container h5 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 162%; /* 38.88px */
}
.signup--section2 .section2-container h6 {
  color: #767676;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 162%;
  text-align: center;
}
.signup--section2 .section2-container .clubs--container div {
  position: relative;
}
.signup--section2 .section2-container .clubs--container div img {
  border: 1px solid #ccc;
}
.signup--section2 .section2-container .clubs--container div p {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 999;
  bottom: 10px;
  position: absolute;
}
.signup--section2 .section2-container .clubs--container div .Club--slide {
  margin: 10px !important;
}
.signup--section2 .section2-container .clubs--container div .swiper-button-next::after,
.signup--section2 .section2-container .clubs--container div .swiper-rtl .swiper-button-prev::after {
  content: url(/public/assets/img/kareify/left.png) !important;
}
.signup--section2 .section2-container .clubs--container div .swiper-button-prev::after,
.signup--section2 .section2-container .clubs--container div .swiper-rtl .swiper-button-next::after {
  content: url(/public/assets/img/kareify/right.png) !important;
}

.kareify--SignUp {
  background-image: url("/public/assets/img/kareify/signUp_bg.webp");
  background-size: 100% auto;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .kareify--SignUp {
    background-size: 100% 100%;
  }
  .kareify--SignUp .signUp-container {
    padding: 50px 0;
    background: linear-gradient(0deg, #000 19.26%, rgba(0, 0, 0, 0.4) 100%) !important;
  }
}
.kareify--SignUp .welcome-text {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 49.4px */
  text-align: center;
}
.kareify--SignUp .welcome-text-md {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-style: italic;
  font-weight: 500;
  line-height: 140%; /* 89.6px */
}
.kareify--SignUp .signUp-container {
  padding: 50px 0;
  background: linear-gradient(0deg, #000 -9.26%, rgba(0, 0, 0, 0.3) 100%);
}
.kareify--SignUp .close-btn {
  margin: 0px 50px 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.kareify--SignUp h2 {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 17.354px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.971px; /* 103.554% */
}
@media only screen and (max-width: 500px) {
  .kareify--SignUp .SignIn--box {
    margin-right: 0px !important;
    background: #ffffff !important;
  }
  .kareify--SignUp .kareify--SignIn h2 {
    margin-right: 0px !important;
  }
}
.kareify--SignUp .SignIn--box {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.75);
  padding: 30px 0;
  margin-right: 90px;
}
.kareify--SignUp .SignIn--box input {
  border-radius: 9px !important;
  border: 1px solid #cecece !important;
  background: #fff !important;
  height: 56px !important;
  padding-left: 15px;
}
.kareify--SignUp .SignIn--box input::-moz-placeholder {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignUp .SignIn--box input::placeholder {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignUp .SignIn--box .form-select {
  border-radius: 9px !important;
  border: 1px solid #cecece !important;
  background: #fff !important;
  height: 56px !important;
}
.kareify--SignUp .SignIn--box .form-select::-moz-placeholder {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignUp .SignIn--box .form-select::placeholder {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignUp .SignIn--box .form-select:first-child {
  color: #989898;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignUp .SignIn--box h6 {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.68px;
}
.kareify--SignUp .SignIn--box h3 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kareify--SignUp .SignIn--box .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 0 !important;
}
.kareify--SignUp .SignIn--box .horizontal--line hr {
  border-bottom: 2px solid #b0b0b0;
  width: 100%;
}
.kareify--SignUp .SignIn--box .horizontal--line p {
  color: #626262;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kareify--SignUp .SignIn--box p {
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignUp .SignIn--box p span {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}
.kareify--SignUp .SignIn--box button {
  border-radius: 10px;
  background: var(--button-primary-color);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px;
}
.kareify--SignUp .SignIn--box .MuiInputBase-input {
  background: #fff;
  border-radius: 10px !important;
}

.otp-container {
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.otp-container input {
  height: 70px;
  width: 50px !important;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  border: none !important;
  border-bottom: 2px solid #000 !important;
}

.kareify--SignIn .Cerified--desc {
  width: 70%;
  color: #fff;
  margin: 15px auto;
  text-align: center;
  font-size: 1rem;
}
@media only screen and (max-width: 500px) {
  .kareify--SignIn .Cerified--desc {
    width: 95%;
    font-size: 0.65rem;
  }
}/*# sourceMappingURL=SignUp.css.map */