.search_bar:focus {
  border: none;
  outline: none;
}

.custom-menu-items {
  padding-left: 0;
}

.custom-menu-item {
  font-size: 1rem;
  list-style: none;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.custom-menu-item:hover {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.menu-icon {
  margin-right: 10px; /* Adjust the spacing between the icon and text */
}

.menu-text {
  display: inline-block;
  vertical-align: middle; /* Adjust the vertical alignment as needed */
}

.icons {
  display: none;
}

.options_tabs:hover .icons {
  display: inline;
}

.userdashboard--Navbar {
  box-shadow: 0 0 20px 0 rgba(63, 63, 63, 0.1);
  z-index: 999;
}
@media only screen and (max-width: 600px) {
  .userdashboard--Navbar .container-fluid {
    flex-direction: column;
    margin: 0 !important;
    padding: 0 20px;
    gap: 20px;
  }
  .userdashboard--Navbar .container-fluid > div {
    flex: 1;
    width: 100%;
  }
}
.userdashboard--Navbar .userdashboard--Search {
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .userdashboard--Navbar .userdashboard--Search {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .userdashboard--Navbar .userdashboard--Search__BTN {
    display: block !important;
  }
}

.listdrop {
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/** cards styling**/
/* SoftUICardLayout.css */
/*
  .dashboard_card::-webkit-scrollbar {
    display: none;
  }

  .dashboard_card {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  }
 */
.left-card,
.cardsl {
  background-color: #fefefe;
  border-radius: 10px;
}

.rectangle-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(10, 10, 10, 0.1);
}

.scroll-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(10, 10, 10, 0.155);
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  width: 100%;
}

.assessment-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(10, 10, 10, 0.155);
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 200px;
  max-width: 200px;
  width: 100%;
}
.assessment-card .title-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardfooter {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scroll-card img {
  width: 65px;
  height: 65px;
  /* margin-bottom: 10px; */
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  padding: 0px 0 10px;
}

.title1,
.title2 {
  font-size: 15px;
  font-weight: bold;
}

.button {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.inputfeild:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.inputrow {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.inputrow {
  border: 1px solid #610c9f;
}

.userDashboard--moods,
.userDashboard--appointments,
.userDashboard--stella {
  position: relative;
  padding: 10px;
  flex: 1;
  padding: 15px 18px;
}
.userDashboard--moods img.rounded,
.userDashboard--appointments img.rounded,
.userDashboard--stella img.rounded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px !important;
}
.userDashboard--moods .card-content,
.userDashboard--appointments .card-content,
.userDashboard--stella .card-content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}

.userDashboard--stella {
  flex: 1.25;
}
@media only screen and (max-width: 600px) {
  .userDashboard--stella .userDashboard--stella__items span {
    flex: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .userDashboard--cardsContainer {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 600px) {
  .userDashboard--cardsContainer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .professionalHelp--Card .rectangle-card > div {
    justify-content: center;
  }
  .professionalHelp--Card .rectangle-card > div img {
    margin: auto;
  }
  .professionalHelp--Card .rectangle-card > div h6 {
    text-align: center;
  }
}

.userdashboardDrawerClosed main {
  width: calc(100vw - 81px);
}

.userdashboardDrawerOpen main {
  width: calc(100vw - 256px);
}

.Policy--Page {
  background-color: rgb(244, 244, 244);
  min-height: 100vh;
  padding: 50px;
}
.Policy--Page .Exisiting-Policy--Container {
  max-width: 1080px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin: auto;
  min-height: 100%;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left {
  grid-column: span 1/span 1;
  background-color: white;
  padding: 20px;
  min-height: 100%;
  border-radius: 10px;
  gap: 20px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card {
  min-height: 148px;
  border-radius: 12px;
  position: relative;
  padding: 0px;
  margin: 15px 0;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card:nth-child(2n) {
  margin-right: 0;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .bg-img {
  border-radius: 12px;
  min-height: 165px;
  width: 100%;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .card-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  line-height: large;
  height: 100%;
  padding: 20px;
  color: white;
  width: 100%;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .card-content .insurance-name {
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .card-content .insurance-name img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .card-content .verified {
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .card-content .member-id {
  color: #101828;
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Left .Insurance-Detail-Card .card-content .policy-number {
  color: #101828;
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right {
  grid-column: span 2/span 2;
  background-color: white;
  min-height: 100%;
  max-height: 100%;
  border-radius: 10px;
  padding: 20px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  background: #eaecf0;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details img {
  width: 79px;
  height: 79px;
  border-radius: 10px;
  background-color: white;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content .insurance-name {
  color: var(--colors-text-text-primary-900, #101828);
  text-shadow: 0px 1px 1px rgba(66, 155, 92, 0.1);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content .verified {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content .member-id,
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content .policy-number {
  color: var(--colors-text-text-tertiary-600, #475467);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content .member-id span,
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .insurance-details .card-content .policy-number span {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container {
  margin: 20px 0;
  min-height: 50vh;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row {
  padding: 10px;
  border-bottom: 1px solid #eaecf0;
  background: #fff;
  display: flex;
  justify-content: space-between;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row .expert-img {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row .history-details p {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row .history-details .expert-name {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row .history-details .session-date {
  color: var(--colors-text-text-quaternary-500, #667085);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row .hover-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row:hover {
  background-color: rgba(234, 236, 240, 0.6);
}
.Policy--Page .Exisiting-Policy--Container .Exisitng-Policy--Container__Right .claim-history-container .claim-history-row:hover .hover-icon {
  opacity: 1; /* Make the icon visible */
}
.Policy--Page .Add-policy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 30px;
}
.Policy--Page .Add-policy-container .Add-policy-container__Left {
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  height: 100%;
  padding: 20px;
}
.Policy--Page .Add-policy-container .Add-policy-container__Right {
  display: flex;
  height: 100%;
  justify-content: center;
  flex: 2;
  border-radius: 0px 10px 10px 0px;
  background-color: #eaecf0;
  padding: 30px;
}
.Policy--Page .Add-policy-container .Add-policy-container__Right .add-policy-img {
  width: 100%;
  min-height: 346px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
}

.Company-list-modal .MuiPaper-root {
  min-width: 500px !important;
  max-height: 600px !important;
}

.Claim-History-Modal .MuiPaper-root {
  min-width: 950px !important;
  max-height: 660px !important;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root {
  padding: 0;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container {
  height: 100%;
  padding: 20px;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container .Expert--Info__Header {
  padding: 10px;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container .Expert--Info__Header img {
  width: 108px;
  height: auto;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container .Expert--Info__Header h3 {
  font-size: 1.5rem;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container .Expert--Info__Header p {
  color: #344054;
  font-size: 1.15rem;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container .Info--Grid {
  padding: 10px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  background: rgba(152, 162, 179, 0.1);
  color: #000 !important;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .session-details-container .Info--Grid .text {
  color: #000;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .bill-container {
  background-color: #d0d5dd;
  padding: 30px;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .bill-container .background {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .bill-container .background .receipt {
  border-radius: 10px;
  background: rgba(152, 162, 179, 0.1);
  width: 100%;
  padding: 10px;
}
.Claim-History-Modal .MuiPaper-root .MuiDialogContent-root .Modal-container .bill-container .background .receipt div {
  color: #000 !important;
}

.Add-Policy-Modal .MuiPaper-root {
  max-width: 500px !important;
  max-height: 600px !important;
}

.course_popup_conform_button {
  background-color: #73589b;
  display: flex;
  margin: 16px auto 0px;
  color: #ffffff;
  font-weight: 600;
  padding: 8px 25px;
  border-radius: 5px;
  border: none;
}

.course_popup_conform_text {
  text-align: left;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 25px;
  color: #000000;
  font-size: 18px;
}/*# sourceMappingURL=style.css.map */