@media only screen and (max-width: 600px) {
  .PlanDetails--Header {
    flex-direction: column-reverse !important;
    padding: 20px;
    gap: 0 !important;
  }
}
.PlanDetails--Header .Header--Left {
  flex: 1;
  flex-direction: column;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 2rem;
  max-width: 740px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left {
    padding: 20px 0 !important;
  }
}
.PlanDetails--Header .Header--Left h2 {
  font-family: "Roboto", sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 62.118% */
  letter-spacing: 0.48px;
  background: linear-gradient(92deg, #701a75 36.33%, #0f172a 91.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left h2 {
    font-size: 26px;
    line-height: 21.738px; /* 83.609% */
    letter-spacing: 0.198px;
  }
}
.PlanDetails--Header .Header--Left h6 {
  font-family: "Roboto", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 68.699px;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left h6 {
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    line-height: 28.284px; /* 176.776% */
  }
}
.PlanDetails--Header .Header--Left .PackOffers {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 50px;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left .PackOffers {
    margin-top: 0px;
  }
}
.PlanDetails--Header .Header--Left .PackOffers .Offers--Item {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Number {
  border-radius: 36px;
  display: flex;
  width: 36px;
  height: 36px;
  min-width: 36px;
  padding: 6px 12.4px 8px 12.6px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #000;
}
.PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Desc {
  padding-top: 5px;
}
.PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Desc p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.4px; /* 110% */
  letter-spacing: 0.24px;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Desc p {
    font-size: 15px;
    line-height: 120%; /* 72.461% */
    letter-spacing: 0.099px;
  }
}
.PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Desc p span {
  color: #541cf5;
}
.PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Desc > span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px; /* 160% */
  letter-spacing: 0.54px;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left .PackOffers .Offers--Item .Item--Desc > span {
    font-size: 12px;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.222px;
  }
}
.PlanDetails--Header .Header--Left .PackOffers .SelectedItem .Item--Number {
  background: #541cf5;
  color: #fff;
  border-color: #541cf5;
}
.PlanDetails--Header .Header--Left button {
  display: flex;
  padding: 18.844px 71.7px 18.844px 65.953px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--button-primary-color);
  border: none;
  width: -moz-max-content;
  width: max-content;
  margin-top: 18px;
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.266px; /* 128.48% */
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header .Header--Left button {
    padding: 15.567px 54px 15.567px 54.484px;
    font-size: 18.174px;
    line-height: 23.35px; /* 128.48% */
    margin: 50px auto 0 auto;
  }
}
.PlanDetails--Header img {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .PlanDetails--Header img {
    width: 100%;
  }
}

.PlanDetails--Header.Platinum h2 {
  background: linear-gradient(92deg, #6d7ae8 14.3%, #131313 81.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.PlanDetails--Header.RTL {
  flex-direction: row-reverse;
}

.Stats--Area {
  z-index: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 80px;
  gap: 38px;
  overflow-x: hidden;
}
.Stats--Area > div {
  max-width: 1550px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .Stats--Area > div {
    gap: 30px !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    align-items: baseline !important;
  }
}
@media only screen and (max-width: 600px) {
  .Stats--Area {
    padding: 30px;
  }
}
.Stats--Area .Stat--Item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item {
    gap: 7px;
  }
}
.Stats--Area .Stat--Item h6 {
  font-family: "Roboto", sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 81.032px; /* 95.332% */
  letter-spacing: 1.473px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item h6 {
    font-size: 33.064px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.521px; /* 95.332% */
    letter-spacing: 0.573px;
  }
}
.Stats--Area .Stat--Item > div {
  gap: 10px;
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item > div div {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item > div svg {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item > div p {
    font-family: "Roboto", sans-serif;
    font-size: 12.274px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.365px; /* 133.333% */
  }
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item > div span {
    font-size: 6.137px;
    font-style: normal;
    font-weight: 400;
    line-height: 8.183px; /* 133.333% */
  }
}
.Stats--Area .Stat--Item p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.362px; /* 127.722% */
  letter-spacing: 0.737px;
}
@media only screen and (max-width: 600px) {
  .Stats--Area .Stat--Item p {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 12.2px; /* 110.907% */
    letter-spacing: 0.287px;
  }
}
.Stats--Area .Stat--Item:nth-child(1) h6 {
  color: #20af7c;
}
.Stats--Area .Stat--Item:nth-child(2) h6 {
  color: #ce0058;
}
.Stats--Area .Stat--Item:nth-child(3) h6 {
  color: #541cf5;
}
.Stats--Area .Stat--Item:nth-child(4) h6 {
  color: #a601a9;
}

.Carousel--Area {
  position: relative;
  background-color: #f4f4f4;
  padding: 50px;
  margin-top: -300px;
  z-index: 0;
}
.Carousel--Area .Swiper--Area {
  position: relative;
}
.Carousel--Area .Swiper--Area > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.Carousel--Area .Img--Container {
  width: 100%;
  max-width: 500px;
}
.Carousel--Area .Img--Container img {
  max-width: 500px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Img--Container img {
    max-width: 250px;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area {
    padding: 0;
    margin-top: -160px;
  }
}
.Carousel--Area .Carousel--Top {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Carousel--Top {
    margin-bottom: 10px;
  }
}
.Carousel--Area .Carousel--Top div {
  width: 600px;
  height: 550px;
  border-radius: 50%;
  background-color: #541cf5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  gap: 33px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Carousel--Top div {
    width: 300px;
    height: 300px;
    gap: 5px;
  }
}
.Carousel--Area .Carousel--Top div h4 {
  color: #f4f4f4;
  font-family: "Roboto", sans-serif;
  font-size: 134.164px;
  font-style: normal;
  font-weight: 600;
  line-height: 95.763px; /* 62.118% */
  letter-spacing: 0.871px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Carousel--Top div h4 {
    font-size: 63.111px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 60.211% */
    letter-spacing: 0.356px;
  }
}
.Carousel--Area .Carousel--Top div h6 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 47.637px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.591px; /* 62.118% */
  letter-spacing: 0.269px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Carousel--Top div h6 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: 0.356px;
    padding-bottom: 10px;
  }
}
.Carousel--Area .Psycologist--List {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px 10px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Psycologist--List {
    gap: 11px 5px;
    justify-content: center;
    padding: 18px;
  }
}
.Carousel--Area .Psycologist--List span {
  padding: 2px 10px;
  border-radius: 16px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Psycologist--List span {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
}
.Carousel--Area .Card--Container {
  width: 100%;
  flex: 1;
  max-width: 800px;
}
.Carousel--Area .Card--Container .card {
  width: 100%;
  max-width: 500px;
  margin: auto;
  border-radius: 15px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card {
    padding: 7px !important;
    max-width: 240px;
    margin-top: 15px;
  }
}
.Carousel--Area .Card--Container .card .MuiCardMedia-media {
  aspect-ratio: 3/2;
  height: auto;
  border-radius: 12px;
}
.Carousel--Area .Card--Container .card .MuiCardContent-root {
  padding: 16px 0;
}
.Carousel--Area .Card--Container .card .MuiTypography-h5 {
  color: #101828;
  font-family: "Roboto", sans-serif;
  font-size: 30.613px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 45.92px */
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .MuiTypography-h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 11.166px */
  }
}
.Carousel--Area .Card--Container .card .card--options {
  max-width: 600px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options {
    flex-wrap: wrap;
  }
}
.Carousel--Area .Card--Container .card .card--options > span {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: rgba(159, 214, 139, 0.1);
  padding: 12px 20px;
  color: #4c9234;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22.96px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.511px; /* 111.111% */
  line-height: 14.397px; /* 111.111% */
  width: max-width;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options > span {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 9.422px; /* 111.111% */
    padding: 4.711px 7.538px;
    gap: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options > span svg {
    width: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options .whatsapp--btn {
    padding: 4.711px !important;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options .whatsapp--btn img {
    width: 15px;
  }
}
.Carousel--Area .Card--Container .card .card--options button {
  gap: 10px;
  border-radius: 50px !important;
  border: 0.72px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 0.71983px 1.43967px 0px rgba(16, 24, 40, 0.05);
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 12px 20px;
  border-radius: 20px;
  color: #344054;
  font-family: "Roboto", sans-serif;
  font-size: 17.858px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.511px; /* 142.857% */
  text-transform: capitalize;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options button {
    gap: 5px;
    font-size: 10px;
    font-style: normal;
    line-height: 9.422px; /* 94.222% */
    padding: 4.711px 7.538px;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .card--options button svg {
    width: 12px;
    height: auto;
  }
}
.Carousel--Area .Card--Container .card .MuiCardActions-root button {
  margin: auto;
  display: flex;
  padding: 10px 33px;
  justify-content: center;
  align-items: center;
  gap: 7.198px;
  border-radius: 50px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22.96px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.172px; /* 122.699% */
  text-transform: capitalize;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Card--Container .card .MuiCardActions-root button {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 111.111% */
  }
}
.Carousel--Area .Desc--Area {
  color: #fff;
  text-align: left;
  flex: 1;
  padding-right: 50px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area {
    padding-right: 0;
  }
}
.Carousel--Area .Desc--Area h6 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 45.714px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.5%; /* 60.114px */
  letter-spacing: 0.457px;
  padding: 30px 0;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area h6 {
    font-size: 23.146px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.231px;
    padding: 30px 0 5px;
  }
}
.Carousel--Area .Desc--Area h6 span {
  background: linear-gradient(92deg, #03ff77 6.75%, #01b152 41.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 45.714px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.5%;
  letter-spacing: 0.457px;
}
.Carousel--Area .Desc--Area > button {
  display: flex;
  padding: 20px 30px;
  align-items: flex-start;
  text-transform: capitalize;
  margin-top: 30px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 10px;
  background: var(--button-primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 100% */
  letter-spacing: 0.662px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area > button {
    font-size: 16.219px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.219px; /* 100% */
    letter-spacing: 0.488px;
    padding: 14.745px 22.117px;
    margin: 30px auto;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area ul {
    padding-left: 12px;
  }
}
.Carousel--Area .Desc--Area li {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 22.067px;
  font-style: normal;
  font-weight: 400;
  line-height: 40.29px; /* 182.583% */
  letter-spacing: 0.662px;
  margin-bottom: 12px;
}
.Carousel--Area .Desc--Area li span {
  color: #f5d21c;
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area li span {
    font-size: 18px;
  }
}
.Carousel--Area .Desc--Area li b {
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area li b {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.4px; /* 145.712% */
    letter-spacing: 0.335px;
    margin-bottom: 10px;
  }
}
.Carousel--Area .Desc--Area .Courses--List {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px 10px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area .Courses--List {
    gap: 11px 5px;
    padding-top: 10px;
  }
}
.Carousel--Area .Desc--Area .Courses--List span {
  padding: 2px 10px;
  border-radius: 16px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .Desc--Area .Courses--List span {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
}
.Carousel--Area .swiper {
  width: 100%;
  min-height: 100%;
}
.Carousel--Area .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 100px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper-slide {
    padding: 20px;
  }
}
.Carousel--Area .swiper-slide > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper-slide > img {
    height: 110%;
  }
}
.Carousel--Area .swiper--content {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper--content {
    flex-direction: column;
    gap: 25px;
  }
}
.Carousel--Area .swiper {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper-button-prev {
    background-image: url("../images/arrow-left.svg");
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper-button-next {
    background-image: url("../images/arrow-right.svg");
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
  }
}
.Carousel--Area .swiper-button-prev,
.Carousel--Area .swiper-button-next {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper-button-prev,
  .Carousel--Area .swiper-button-next {
    display: flex !important;
    top: 17%;
    width: 36px;
    height: 36px;
    background-color: #fff;
  }
}
.Carousel--Area .swiper-button-prev::after,
.Carousel--Area .swiper-button-next::after {
  font-size: 1.5rem;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .Carousel--Area .swiper-button-prev::after,
  .Carousel--Area .swiper-button-next::after {
    content: "";
    color: #000;
  }
}
.Carousel--Area .swiper-wrapper {
  align-items: center;
}

.WhyTrustUs--Area {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 100px;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area {
    padding: 20px;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top {
  display: flex;
  align-items: center;
  gap: 100px;
  max-width: 1650px;
  margin: auto;
  padding: 100px 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 200px;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top {
    flex-direction: column;
    width: 100%;
    padding: 10px 0px 140px;
    margin-bottom: 0;
    gap: 50px;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area {
  width: 600px;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area {
    width: 100%;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area h4 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 93.5px */
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area h4 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-align: center;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area h4 span {
  background: linear-gradient(112deg, #00af66 35.63%, #048851 58.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area h4 span {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 48px */
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Desc--Area p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Images--Area {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 19px;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Images--Area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1px;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Images--Area img:nth-child(1) {
  position: relative;
  top: 97px;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Images--Area img:nth-child(1) {
    top: 40px;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Top .Images--Area img:nth-child(3) {
  position: relative;
  top: 48px;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Top .Images--Area img:nth-child(3) {
    top: 20px;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 100px 50px;
  max-width: 1650px;
  width: 100%;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom {
    padding: 0;
    flex-direction: column;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area {
  width: 1000px;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area {
    width: 100%;
    text-align: center;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area h4 {
  color: #fffbeb;
  font-family: "Roboto", sans-serif;
  font-size: 128.733px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%; /* 141.607px */
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area h4 {
    font-size: 51.493px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 56.643px */
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area h6 {
  color: #fffbeb;
  font-family: "Roboto", sans-serif;
  font-size: 60.094px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 82.604px */
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area h6 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area button {
  margin-top: 50px;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  padding: 18.844px 71.7px 18.844px 65.953px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--button-primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.266px; /* 128.48% */
  border: none;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom .Desc--Area button {
    padding: 11.918px 45.968px;
    font-size: 15.323px;
    margin: 30px auto;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom > img {
  display: none;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom > img {
    display: block;
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.WhyTrustUs--Area .WhyTrustUs--Bottom picture img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 82;
}
@media only screen and (max-width: 600px) {
  .WhyTrustUs--Area .WhyTrustUs--Bottom picture img {
    height: 88%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.ThreeImg--Background {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width: 600px) {
  .ThreeImg--Background {
    display: flex;
    min-height: 600px;
  }
}
.ThreeImg--Background picture img {
  display: flex !important;
}
.ThreeImg--Background img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media only screen and (max-width: 600px) {
  .ThreeImg--Background img:nth-child(1) {
    display: none;
  }
  .ThreeImg--Background img:nth-child(2) {
    display: none;
  }
}
.ThreeImg--Background::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #000 -10.71%, rgba(10, 9, 9, 0) 77.23%);
}
@media only screen and (max-width: 600px) {
  .ThreeImg--Background::before {
    display: none;
  }
}
.ThreeImg--Background .Content--Area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1512px;
}
@media only screen and (max-width: 600px) {
  .ThreeImg--Background .Content--Area {
    top: 56%;
    display: flex;
    flex-direction: column;
  }
}
.ThreeImg--Background .Content--Area h6 {
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 110.5px */
}
@media only screen and (max-width: 600px) {
  .ThreeImg--Background .Content--Area h6 {
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 44.2px */
  }
}
.ThreeImg--Background .Content--Area button {
  width: -moz-max-content;
  width: max-content;
  margin-top: 50px;
  border: none;
  display: flex;
  padding: 18.844px 71.7px 18.844px 65.953px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--button-primary-color);
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.266px; /* 128.48% */
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .ThreeImg--Background .Content--Area button {
    padding: 14px 54px;
    font-size: 18px;
    margin-top: 20px;
  }
}

.Reviews--Area {
  position: relative;
  top: -10px;
  padding: 100px 0;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area {
    padding: 50px 20px;
  }
}
.Reviews--Area h6 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 78px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 101.4px */
  text-align: center;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area h6 {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 33.8px */
  }
}
.Reviews--Area > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.Reviews--Area .swiper {
  width: 100%;
  height: 65vh;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper {
    min-height: -moz-max-content;
    min-height: max-content;
    height: 100%;
    margin-top: 30px;
    padding-bottom: 50px;
  }
}
.Reviews--Area .swiper .swiper-horizontal > .swiper-pagination-bullets,
.Reviews--Area .swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  padding: 0 100px;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper .swiper-horizontal > .swiper-pagination-bullets,
  .Reviews--Area .swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
  }
}
.Reviews--Area .swiper .swiper-pagination-bullet-active {
  width: 40px;
  border-radius: 5px;
  background: #701a75 !important;
}
.Reviews--Area .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Reviews--Area .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.Reviews--Area .swiper {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper-button-prev {
    background-image: url("../images/arrow-left-pink.svg");
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper-button-next {
    background-image: url("../images/arrow-right-pink.svg");
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
  }
}
.Reviews--Area .swiper-button-prev,
.Reviews--Area .swiper-button-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper-button-prev,
  .Reviews--Area .swiper-button-next {
    display: flex !important;
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    top: 97.5%;
  }
}
.Reviews--Area .swiper-button-prev::after,
.Reviews--Area .swiper-button-next::after {
  font-size: 1.15rem;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper-button-prev::after,
  .Reviews--Area .swiper-button-next::after {
    content: "";
  }
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper-button-prev {
    left: 56%;
  }
}
.Reviews--Area .swiper--content {
  padding: 0 100px;
  display: flex;
  align-items: center;
  gap: 50px;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper--content {
    padding: 0;
    flex-direction: column;
  }
}
.Reviews--Area .swiper--content .Desc--Section {
  width: 90%;
  text-align: left;
}
.Reviews--Area .swiper--content .Desc--Section p,
.Reviews--Area .swiper--content .Desc--Section div {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 51.2px */
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper--content .Desc--Section p,
  .Reviews--Area .swiper--content .Desc--Section div {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
  }
}
.Reviews--Area .swiper--content .Desc--Section div {
  font-weight: 400;
  margin-top: 20px;
  line-height: 120%;
}
.Reviews--Area .swiper--content .Img--Section {
  position: relative;
  height: 500px;
  width: 100%;
  padding: 50px;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper--content .Img--Section {
    height: auto;
    padding: 0;
  }
}
.Reviews--Area .swiper--content .Img--Section > div {
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 52.364px;
  background: #541cf5;
  top: 50%;
  left: 50%;
  transform: rotate(-4deg) translate(-50%, -57%);
  max-width: 700px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper--content .Img--Section > div {
    display: none;
  }
}
.Reviews--Area .swiper--content .Img--Section .quote {
  width: 60px;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  top: -7px;
  right: 14%;
  height: auto;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper--content .Img--Section .quote {
    display: none;
  }
}
.Reviews--Area .swiper--content .Img--Section img {
  position: relative;
  z-index: 1;
  max-width: 700px;
  margin: auto;
}
.Reviews--Area .swiper--content .Img--Section svg {
  position: absolute;
  z-index: 10;
  bottom: -80px;
  left: 30px;
}
@media only screen and (max-width: 600px) {
  .Reviews--Area .swiper--content .Img--Section svg {
    bottom: -172px;
    left: -4px;
    width: 100px;
  }
}/*# sourceMappingURL=PlanDetailsPage.css.map */