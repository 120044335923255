.Footer--Container {
  font-family: "Roboto";
  padding: 50px 5%;
  background-color: #73589b;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .Footer--Container {
    flex-direction: column;
    gap: 30px;
    margin-top: 15px;
  }
}
.Footer--Container .Footer--Container__Left {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
}
.Footer--Container .Footer--Container__Left p {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}
.Footer--Container .Footer--Container__Left p span {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.Footer--Container .Footer--Container__Left h6 {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 133.333% */
}
@media screen and (max-width: 500px) {
  .Footer--Container .Footer--Container__Left {
    gap: 30px;
  }
}
.Footer--Container .Footer--Container__Left .Newsletter--Input {
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Footer--Container .Footer--Container__Left .Newsletter--Input input {
  flex: 1;
  border: none;
}
.Footer--Container .Footer--Container__Left .Newsletter--Input button {
  border: none;
  border-radius: 7px;
  background-color: #38ab45;
  padding: 6px 23px;
  color: #fff;
  font-size: 16px;
}
@media screen and (max-width: 500px) {
  .Footer--Container .Footer--Container__Middle {
    padding: 0 !important;
  }
}
.Footer--Container .Footer--Container__Middle {
  display: flex;
  align-items: flex-start;
  gap: 100px;
  justify-content: space-between;
  padding: 0 10%;
}
.Footer--Container .Footer--Container__Middle .Middle--Left h6 {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 111.111% */
}
.Footer--Container .Footer--Container__Middle .Middle--Left p {
  color: #d5d5d5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.Footer--Container .Footer--Container__Middle .Middle--Right h6 {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 111.111% */
}
.Footer--Container .Footer--Container__Middle .Middle--Right p {
  color: #d5d5d5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.Footer--Container .Footer--Container__Middle .Middle--Left,
.Footer--Container .Footer--Container__Middle .Middle--Right {
  flex: 1;
  color: #fff;
}
.Footer--Container .Footer--Container__Middle .Middle--Left > div,
.Footer--Container .Footer--Container__Middle .Middle--Right > div {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.Footer--Container .Footer--Container__Right {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Footer--Container h6 {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 111.111% */
}

.Footer--Copy {
  color: #fff;
  padding: 10px 5%;
  background-color: #73589b;
  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}/*# sourceMappingURL=Footer2.css.map */